body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sim-card-content {
  margin: 30px 50px;
  text-align:center;
}

.sim-greysmall {
  font-size:12px;
  color:grey;
}

.sim-error {
  color: red;
}

.sim-text-link {
  cursor: pointer;
}

.sim-text-link:hover {
  text-decoration: underline;
}

.sim-text-field {
  margin-bottom: 10px !important;
}

.sim-select {
  margin-bottom: 10px !important;
}

/* Removing Arrows from Number Input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

.back-link {
  margin-bottom: 10px;
}